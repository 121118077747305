var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-overlay",
    { attrs: { show: _vm.loading } },
    [
      _c(
        "b-tabs",
        {
          attrs: {
            align: "center",
            "active-nav-item-class": "font-weight-bold",
            "nav-class": "coding-tab",
            "active-tab-class": "",
            "content-class": "text-dark"
          },
          model: {
            value: _vm.currenttab,
            callback: function($$v) {
              _vm.currenttab = $$v
            },
            expression: "currenttab"
          }
        },
        [
          _c(
            "b-tab",
            {
              attrs: {
                "title-item-class": "coding-tab",
                "title-link-class": "coding-tab tab-item"
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._v("Coding"),
                      _vm.codingTotal > 0
                        ? _c("span", [
                            _vm._v(" (" + _vm._s(_vm.codingTotal) + ")")
                          ])
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "b-card",
                { staticClass: "mt-0" },
                [
                  _c(
                    "b-card-text",
                    [
                      _c("Coding", {
                        attrs: {
                          companies: _vm.companies,
                          companyId: _vm.selectedCompanyId,
                          date: _vm.date
                        },
                        on: {
                          selectCompany: function($event) {
                            return _vm.setValAndRefresh(
                              "selectedCompanyId",
                              $event
                            )
                          },
                          selectPeriod: function($event) {
                            return _vm.setValAndRefresh(
                              "selectedPeriod",
                              $event
                            )
                          },
                          total: function($event) {
                            return _vm.setValAndRefresh(
                              "codingTotal",
                              $event,
                              "number",
                              false
                            )
                          },
                          refresh: _vm.triggerRefresh,
                          selectTab: _vm.setCurrentTab
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: {
                "title-item-class": "queue-tab",
                "title-link-class": "queue-tab tab-item"
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._v("Queue"),
                      _vm.queueTotal > 0
                        ? _c("span", [
                            _vm._v(" (" + _vm._s(_vm.queueTotal) + ")")
                          ])
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "b-card",
                { staticClass: "mt-0" },
                [
                  _c(
                    "b-card-text",
                    [
                      _c("TransactionQueue", {
                        attrs: {
                          company: _vm.selectedCompany,
                          period: _vm.selectedPeriod,
                          refresh: _vm.refresh
                        },
                        on: {
                          refresh: _vm.triggerRefresh,
                          total: function($event) {
                            return _vm.setValAndRefresh(
                              "queueTotal",
                              $event,
                              "number",
                              false
                            )
                          },
                          switchtab: function($event) {
                            return _vm.setCurrentTab($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: {
                "title-item-class": "processing-tab",
                "title-link-class": "processing-tab tab-item"
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._v("Processing"),
                      _vm.processingTotal > 0
                        ? _c("span", [
                            _vm._v(" (" + _vm._s(_vm.processingTotal) + ")")
                          ])
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "b-card",
                { staticClass: "mt-0" },
                [
                  _c(
                    "b-card-text",
                    [
                      _c("Processing", {
                        attrs: {
                          company: _vm.selectedCompany,
                          period: _vm.selectedPeriod,
                          refresh: _vm.refresh
                        },
                        on: {
                          refresh: _vm.triggerRefresh,
                          total: function($event) {
                            return _vm.setValAndRefresh(
                              "processingTotal",
                              $event,
                              "number",
                              false
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: {
                "title-item-class": "feedback-tab",
                "title-link-class": "feedback-tab tab-item"
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._v("Feedback"),
                      _vm.feedbackTotal > 0
                        ? _c("span", [
                            _vm._v(" (" + _vm._s(_vm.feedbackTotal) + ")")
                          ])
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-card-text",
                    [
                      _c("Feedback", {
                        attrs: {
                          company: _vm.selectedCompany,
                          period: _vm.selectedPeriod,
                          refresh: _vm.refresh
                        },
                        on: {
                          refresh: _vm.triggerRefresh,
                          total: function($event) {
                            return _vm.setValAndRefresh(
                              "feedbackTotal",
                              $event,
                              "number",
                              false
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: {
                "title-item-class": "completed-tab",
                "title-link-class": "completed-tab tab-item"
              },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._v("Completed"),
                      _vm.completedTotal > 0
                        ? _c("span", [
                            _vm._v(" (" + _vm._s(_vm.completedTotal) + ")")
                          ])
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-card-text",
                    [
                      _c("Complete", {
                        attrs: {
                          company: _vm.selectedCompany,
                          refresh: _vm.refresh,
                          period: _vm.selectedPeriod
                        },
                        on: {
                          refresh: _vm.triggerRefresh,
                          total: function($event) {
                            return _vm.setValAndRefresh(
                              "completedTotal",
                              $event,
                              "number",
                              false
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-tab",
            {
              attrs: { "title-link-class": "tab-item" },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [
                      _c(
                        "span",
                        {
                          class:
                            +_vm.needsauthTotal > 0
                              ? "text-danger font-weight-bold"
                              : ""
                        },
                        [
                          _vm._v("Settings"),
                          _vm.needsauthTotal > 0
                            ? _c("span", [
                                _vm._v(" (" + _vm._s(_vm.needsauthTotal) + ")")
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-card-text",
                    [
                      +_vm.selectedCompanyId
                        ? _c("PlaidMap", {
                            attrs: { companyId: +_vm.selectedCompanyId },
                            on: {
                              refresh: _vm.triggerRefresh,
                              total: function(val) {
                                _vm.needsauthTotal = +val
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }