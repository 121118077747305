var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.company
    ? _c(
        "b-overlay",
        { attrs: { show: _vm.loading } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "text-left" }, [
                _c("h2", [_vm._v(_vm._s(_vm.company.text))])
              ]),
              _c(
                "b-col",
                { staticClass: "text-right small" },
                [
                  _c("QueueStatus", {
                    attrs: { status: _vm.status },
                    on: {
                      refresh: function($event) {
                        return _vm.$root.$emit(
                          "bv::refresh::table",
                          "completedTable"
                        )
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("b-table", {
            staticClass: "small",
            attrs: {
              id: "completedTable",
              fields: _vm.headers,
              items: _vm.getRecords,
              "sort-by": _vm.sort.column,
              "sort-desc": _vm.sort.reverse,
              bordered: "",
              "sticky-header": "600px",
              "show-empty": "",
              "primary-key": "id",
              "tbody-tr-class": "align-middle",
              striped: ""
            },
            on: {
              "update:sortBy": function($event) {
                return _vm.$set(_vm.sort, "column", $event)
              },
              "update:sort-by": function($event) {
                return _vm.$set(_vm.sort, "column", $event)
              },
              "update:sortDesc": function($event) {
                return _vm.$set(_vm.sort, "reverse", $event)
              },
              "update:sort-desc": function($event) {
                return _vm.$set(_vm.sort, "reverse", $event)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(linkedqbaccount)",
                  fn: function(data) {
                    return [
                      _c(
                        "a",
                        {
                          staticStyle: { color: "black" },
                          attrs: { href: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.viewTransaction(data.item)
                            }
                          }
                        },
                        [_vm._v(_vm._s(data.item.linkedqbaccount))]
                      )
                    ]
                  }
                },
                {
                  key: "cell(qbtype)",
                  fn: function(data) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(data.item.qbtype.replace(/([A-Z])/g, " $1")) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "cell(amount)",
                  fn: function(data) {
                    return [
                      _c("strong", [
                        _vm._v(_vm._s(_vm._f("currency")(data.item.amount)))
                      ])
                    ]
                  }
                },
                {
                  key: "cell(started)",
                  fn: function(data) {
                    return [
                      data.item.errorcode
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              " " +
                                _vm._s(data.errorcode) +
                                ": " +
                                _vm._s(data.error) +
                                " "
                            )
                          ])
                        : data.item.completed
                        ? _c("span", [_vm._v("Success")])
                        : data.item.started
                        ? _c("span", [_vm._v("Processing")])
                        : _c("span", [_vm._v("Queued")])
                    ]
                  }
                }
              ],
              null,
              false,
              2980431877
            )
          }),
          _c(
            "b-row",
            { staticClass: "h-100" },
            [
              _c("b-col", { staticClass: "text-left" }, [
                _vm._v(_vm._s(_vm.records.length) + " transactions processing")
              ]),
              _c(
                "b-col",
                { staticClass: "text-center" },
                [
                  +_vm.totalRecords > +_vm.perPage
                    ? _c("b-pagination", {
                        staticClass: "my-0",
                        attrs: {
                          "total-rows": _vm.totalRecords,
                          "per-page": _vm.perPage,
                          align: "fill",
                          size: "sm",
                          "aria-controls": "completedTable"
                        },
                        model: {
                          value: _vm.currentPage,
                          callback: function($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c("b-col", { staticClass: "text-right" }, [
                _vm._v(" Total: "),
                _c("strong", [_vm._v(_vm._s(_vm._f("currency")(_vm.total)))])
              ])
            ],
            1
          ),
          _vm.selectedTransaction
            ? _c(
                "b-modal",
                {
                  attrs: {
                    size: "lg",
                    "no-close-on-backdrop": "",
                    "no-close-on-esc": ""
                  },
                  on: {
                    close: function($event) {
                      _vm.selectedTransaction = null
                    },
                    cancel: function($event) {
                      _vm.selectedTransaction = null
                    }
                  },
                  model: {
                    value: _vm.showTransactionDetail,
                    callback: function($$v) {
                      _vm.showTransactionDetail = $$v
                    },
                    expression: "showTransactionDetail"
                  }
                },
                [
                  _c("TransactionDetail", {
                    attrs: { transaction: _vm.selectedTransaction }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }