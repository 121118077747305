var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.status && _vm.status !== null
    ? _c(
        "div",
        [
          _c("strong", [
            _vm._v("Status: "),
            _c(
              "span",
              {
                class:
                  _vm.status.status === "OK" ? "text-success" : "text-danger"
              },
              [
                _c("b-icon", {
                  attrs: {
                    icon:
                      _vm.status.status === "OK"
                        ? "check-circle-fill"
                        : "x-circle-fill"
                  }
                }),
                _vm._v(" " + _vm._s(_vm.status.status) + " ")
              ],
              1
            )
          ]),
          _c("br"),
          _vm._v(
            " " +
              _vm._s(
                _vm.status.ts !== null ? _vm.status.ts.substring(5, 16) : ""
              ) +
              " "
          ),
          _c("b-icon-arrow-repeat", {
            staticClass: "text-primary pointer",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.$emit("refresh")
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }