var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.company
    ? _c(
        "b-overlay",
        { attrs: { show: _vm.loading } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "text-left" }, [
                _c("h2", [_vm._v(_vm._s(_vm.company.text))])
              ]),
              _c(
                "b-col",
                { staticClass: "text-right small" },
                [
                  _c("QueueStatus", {
                    attrs: { status: _vm.status },
                    on: {
                      refresh: function($event) {
                        return _vm.$root.$emit(
                          "bv::refresh::table",
                          "feedbackTable"
                        )
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("b-table", {
            staticClass: "small",
            attrs: {
              id: "feedbackTable",
              fields: _vm.headers,
              items: _vm.getRecords,
              "sort-by": _vm.sort.column,
              "sort-desc": _vm.sort.reverse,
              bordered: "",
              "sticky-header": "600px",
              "show-empty": "",
              "primary-key": "id",
              "tbody-tr-class": "align-middle",
              striped: "",
              "details-td-class": "w-100 p-0"
            },
            on: {
              "update:sortBy": function($event) {
                return _vm.$set(_vm.sort, "column", $event)
              },
              "update:sort-by": function($event) {
                return _vm.$set(_vm.sort, "column", $event)
              },
              "update:sortDesc": function($event) {
                return _vm.$set(_vm.sort, "reverse", $event)
              },
              "update:sort-desc": function($event) {
                return _vm.$set(_vm.sort, "reverse", $event)
              },
              "row-clicked": function(item) {
                return _vm.toggleDetailRow(item)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(qbtype)",
                  fn: function(data) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(data.item.qbtype.replace(/([A-Z])/g, " $1")) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "cell(credit)",
                  fn: function(data) {
                    return [
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              data.item.debit !== 0
                                ? data.item.debit
                                : data.item.credit
                            )
                          )
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "cell(started)",
                  fn: function(data) {
                    return [
                      data.item.messagesunread > 0
                        ? _c("span", [
                            _c("strong", [
                              _vm._v(
                                "Unread (" +
                                  _vm._s(data.item.messagesunread) +
                                  ")"
                              )
                            ])
                          ])
                        : !data.item.messages.length
                        ? _c("span", [_vm._v("Needs Info")])
                        : data.item.messagesneedresponse
                        ? _c("span", [_vm._v("Needs Response")])
                        : _c("span", [_vm._v("Responded")])
                    ]
                  }
                },
                {
                  key: "row-details",
                  fn: function(row) {
                    return [
                      _c("Messaging", {
                        attrs: { transaction: row.item },
                        on: {
                          toggle: row.toggleDetails,
                          update: _vm.updateMessage,
                          refresh: function($event) {
                            return _vm.$emit("refresh")
                          }
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              false,
              81830596
            )
          }),
          _c(
            "b-row",
            { staticClass: "h-100" },
            [
              _c("b-col", { staticClass: "text-left" }, [
                _vm._v(_vm._s(_vm.records.length) + " transactions processing")
              ]),
              _c("b-col", { staticClass: "text-right" }, [
                _vm._v(" Total: "),
                _c("strong", [_vm._v(_vm._s(_vm._f("currency")(_vm.total)))])
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }