import { render, staticRenderFns } from "./Queue.vue?vue&type=template&id=e3bc8914&"
import script from "./Queue.vue?vue&type=script&lang=js&"
export * from "./Queue.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/websites/streamlined-business-solutions/sbs-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e3bc8914')) {
      api.createRecord('e3bc8914', component.options)
    } else {
      api.reload('e3bc8914', component.options)
    }
    module.hot.accept("./Queue.vue?vue&type=template&id=e3bc8914&", function () {
      api.rerender('e3bc8914', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/bookkeeping/Queue.vue"
export default component.exports