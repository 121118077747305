var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.company
    ? _c(
        "b-overlay",
        { attrs: { show: _vm.loading } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "text-left" }, [
                _c("h2", [_vm._v(_vm._s(_vm.company.text))])
              ]),
              _c(
                "b-col",
                { staticClass: "text-right small" },
                [
                  _c("QueueStatus", {
                    attrs: { status: _vm.status },
                    on: {
                      refresh: function($event) {
                        return _vm.$root.$emit(
                          "bv::refresh::table",
                          "queueTable"
                        )
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("b-table", {
            staticClass: "small",
            attrs: {
              id: "queueTable",
              fields: _vm.headers,
              items: _vm.getRecords,
              "sort-by": _vm.sort.column,
              "sort-desc": _vm.sort.reverse,
              bordered: "",
              "sticky-header": "600px",
              "show-empty": "",
              "primary-key": "id",
              "tbody-tr-class": "align-middle",
              striped: ""
            },
            on: {
              "update:sortBy": function($event) {
                return _vm.$set(_vm.sort, "column", $event)
              },
              "update:sort-by": function($event) {
                return _vm.$set(_vm.sort, "column", $event)
              },
              "update:sortDesc": function($event) {
                return _vm.$set(_vm.sort, "reverse", $event)
              },
              "update:sort-desc": function($event) {
                return _vm.$set(_vm.sort, "reverse", $event)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "cell(qbtype)",
                  fn: function(data) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(data.item.qbtype.replace(/([A-Z])/g, " $1")) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "cell(qbentity)",
                  fn: function(data) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            data.item.qbentity
                              ? data.item.qbentity
                              : data.item.vendorname
                          ) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "cell(amount)",
                  fn: function(data) {
                    return [
                      _c("strong", [
                        _vm._v(_vm._s(_vm._f("currency")(data.item.amount)))
                      ])
                    ]
                  }
                },
                {
                  key: "cell(delete)",
                  fn: function(data) {
                    return [
                      _c("b-icon-info-circle-fill", {
                        staticClass: "pointer text-primary mr-2",
                        attrs: { title: "Transaction Details" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.selectedTransaction = data.item
                            _vm.showTransactionDetail = true
                          }
                        }
                      }),
                      _c("b-icon-trash-fill", {
                        staticClass: "pointer text-danger",
                        attrs: { title: "Move Back to Coding" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.selectedTransaction = data.item
                            _vm.showTransactionRecode = true
                          }
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              false,
              2598753339
            )
          }),
          _c(
            "b-row",
            { staticClass: "h-100" },
            [
              _c("b-col", { staticClass: "text-left" }, [
                _vm._v(_vm._s(_vm.records.length) + " transactions queued")
              ]),
              _c(
                "b-col",
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "danger",
                        disabled: _vm.loading,
                        size: "sm"
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.showProcessQueueConfirmation = true
                        }
                      }
                    },
                    [_vm._v("Process Queue")]
                  )
                ],
                1
              ),
              _c("b-col", { staticClass: "text-right" }, [
                _vm._v(" Total: "),
                _c("strong", [_vm._v(_vm._s(_vm._f("currency")(_vm.total)))])
              ])
            ],
            1
          ),
          _vm.selectedTransaction
            ? _c(
                "b-modal",
                {
                  attrs: {
                    size: "lg",
                    "no-close-on-backdrop": "",
                    "no-close-on-esc": ""
                  },
                  on: {
                    close: function($event) {
                      _vm.selectedTransaction = null
                    },
                    cancel: function($event) {
                      _vm.selectedTransaction = null
                    }
                  },
                  model: {
                    value: _vm.showTransactionDetail,
                    callback: function($$v) {
                      _vm.showTransactionDetail = $$v
                    },
                    expression: "showTransactionDetail"
                  }
                },
                [
                  _c("TransactionDetail", {
                    attrs: { transaction: _vm.selectedTransaction }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-modal",
            {
              attrs: {
                size: "md",
                title: "Process Transactions",
                "button-size": "sm",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                "ok-variant": "danger",
                "ok-title": "Process"
              },
              on: { ok: _vm.process },
              model: {
                value: _vm.showProcessQueueConfirmation,
                callback: function($$v) {
                  _vm.showProcessQueueConfirmation = $$v
                },
                expression: "showProcessQueueConfirmation"
              }
            },
            [
              _c("p", { staticClass: "text-center" }, [
                _vm._v(
                  "Are you sure you want to process all " +
                    _vm._s(this.records.length) +
                    " transactions in the queue?"
                )
              ])
            ]
          ),
          _vm.selectedTransaction
            ? _c(
                "b-modal",
                {
                  attrs: {
                    size: "md",
                    title: "Re-Code Transaction",
                    "button-size": "sm",
                    "ok-variant": "danger",
                    "ok-title": "Re-Code",
                    "no-close-on-backdrop": "",
                    "no-close-on-esc": ""
                  },
                  on: {
                    close: function($event) {
                      _vm.selectedTransaction = null
                    },
                    cancel: function($event) {
                      _vm.selectedTransaction = null
                    },
                    ok: function($event) {
                      return _vm.recodeTransaction(_vm.selectedTransaction)
                    }
                  },
                  model: {
                    value: _vm.showTransactionRecode,
                    callback: function($$v) {
                      _vm.showTransactionRecode = $$v
                    },
                    expression: "showTransactionRecode"
                  }
                },
                [
                  _c("p", { staticClass: "text-center" }, [
                    _vm._v(
                      "Are you sure you want to move this transaction back to coding?"
                    )
                  ]),
                  _c(
                    "b-table-simple",
                    { attrs: { small: "", bordered: "" } },
                    [
                      _c(
                        "b-thead",
                        [
                          _c(
                            "b-tr",
                            [
                              _c("b-th", [_vm._v("Date")]),
                              _c("b-th", { staticClass: "text-right" }, [
                                _vm._v("Amount")
                              ]),
                              _c("b-th", [_vm._v("Details")])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-tbody",
                        [
                          _c(
                            "b-tr",
                            [
                              _c("b-td", [
                                _vm._v(
                                  _vm._s(_vm.selectedTransaction.businessdate)
                                )
                              ]),
                              _c("b-td", { staticClass: "text-right" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.selectedTransaction.amount
                                    )
                                  )
                                )
                              ]),
                              _c("b-td", [
                                _vm._v(_vm._s(_vm.selectedTransaction.name))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }