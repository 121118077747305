var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        size: "sm",
        title: "Add New Vendor",
        "no-close-on-backdrop": "",
        "no-close-on-esc": ""
      },
      on: {
        cancel: function($event) {
          return _vm.$emit("cancel")
        },
        ok: function($event) {
          return _vm.$emit("newvendor", _vm.name)
        },
        shown: function($event) {
          return _vm.focusInput()
        }
      },
      model: {
        value: _vm.showAddVendor,
        callback: function($$v) {
          _vm.showAddVendor = $$v
        },
        expression: "showAddVendor"
      }
    },
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.$emit("newvendor", _vm.name)
            }
          }
        },
        [
          _c("p", { staticClass: "text-center" }, [
            _vm._v("Enter the new vendor name:")
          ]),
          _c("b-form-input", {
            attrs: { id: "newVendorName-" + _vm.randomId },
            model: {
              value: _vm.name,
              callback: function($$v) {
                _vm.name = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "name"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }