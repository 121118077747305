var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    {
      staticClass: "text-center mb-1",
      attrs: { "no-gutters": "", "align-v": "center" }
    },
    [
      _c("b-col", { attrs: { cols: "12" } }, [
        _c(
          "span",
          {
            staticClass: "font-weight-bold mb-0 pointer",
            on: {
              click: function($event) {
                _vm.edit = !_vm.edit
              }
            }
          },
          [
            _vm._v("Vendor: " + _vm._s(_vm.name ? _vm.name : "Unknown") + " "),
            _c("b-icon", {
              attrs: {
                variant: !_vm.name ? "warning" : "success",
                icon: _vm.name
                  ? "check-circle-fill"
                  : "exclamation-octagon-fill"
              }
            })
          ],
          1
        )
      ]),
      _vm.edit
        ? _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-input-group",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append",
                        fn: function() {
                          return [
                            _c(
                              "b-button",
                              {
                                staticClass: "small-select",
                                attrs: {
                                  variant: "outline-info",
                                  size: "sm",
                                  title: "Add New Vendor"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.showAdd = true
                                  }
                                }
                              },
                              [_c("b-icon-plus-circle-fill")],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3881985242
                  )
                },
                [
                  _c("b-form-select", {
                    staticClass: "small-select",
                    class: _vm.selection
                      ? _vm.coded
                        ? "is-valid"
                        : "is-warning"
                      : "is-warning",
                    attrs: {
                      options: _vm.options,
                      size: "sm",
                      title: "Quickbooks entity"
                    },
                    on: {
                      change: function($event) {
                        return _vm.$emit("selected", $event)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "first",
                          fn: function() {
                            return [
                              _c(
                                "b-form-select-option",
                                { attrs: { value: null } },
                                [_vm._v("Select Vendor")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1037170962
                    ),
                    model: {
                      value: _vm.selection,
                      callback: function($$v) {
                        _vm.selection = $$v
                      },
                      expression: "selection"
                    }
                  })
                ],
                1
              ),
              _c("AddVendor", {
                attrs: { show: _vm.showAdd },
                on: {
                  cancel: function($event) {
                    _vm.showAdd = false
                  },
                  newvendor: function(v) {
                    _vm.setNewVendorName(v)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }