var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-overlay",
    { attrs: { show: _vm.loading } },
    [
      _vm._l(_vm.company.plaid, function(i) {
        return _c(
          "b-card",
          { key: i.id, staticClass: "m-2" },
          [
            _c(
              "b-card-title",
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", [_vm._v(_vm._s(i.institution))]),
                    _c("b-col", [
                      _vm._v(
                        _vm._s(
                          i.accounts && i.accounts.length
                            ? i.accounts.length + " Accounts"
                            : "Pending"
                        )
                      )
                    ]),
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-btn",
                          {
                            attrs: { variant: "danger", size: "sm" },
                            on: {
                              click: function($event) {
                                return _vm.remove(i)
                              }
                            }
                          },
                          [_vm._v("Remove")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            i.accounts.length > 0
              ? _c(
                  "b-table-simple",
                  [
                    _c(
                      "b-thead",
                      [
                        _c(
                          "b-tr",
                          [
                            _c("b-th", [_vm._v("Institution")]),
                            _c("b-th", [_vm._v("Type")]),
                            _c("b-th", [_vm._v("Account Name")]),
                            _c("b-th", [_vm._v("Last Four")]),
                            _c("b-th", [_vm._v("QuickBooks Account")]),
                            _c("b-th", { staticClass: "text-center" }, [
                              _vm._v("Ignore")
                            ]),
                            _c("b-th", { staticClass: "text-center" }, [
                              _vm._v("Auto-Settle")
                            ]),
                            _c("b-th", { staticClass: "text-center" })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-tbody",
                      _vm._l(i.accounts, function(a) {
                        return _c(
                          "b-tr",
                          { key: a.id },
                          [
                            _c("b-td", { staticClass: "align-middle" }, [
                              _vm._v(_vm._s(a.institution))
                            ]),
                            _c("b-td", { staticClass: "align-middle" }, [
                              _vm._v(_vm._s(a.type) + " / " + _vm._s(a.subtype))
                            ]),
                            _c("b-td", { staticClass: "align-middle" }, [
                              _vm._v(_vm._s(a.name)),
                              _c("br"),
                              _c("span", { staticClass: "small" }, [
                                _c("strong", [_vm._v("Plaid Item ID: ")]),
                                _vm._v(_vm._s(a.plaiditem_externalid))
                              ]),
                              _c("br"),
                              _c("span", { staticClass: "small" }, [
                                _c("strong", [_vm._v("Link Session ID: ")]),
                                _vm._v(_vm._s(a.session_id.replaceAll('"', "")))
                              ])
                            ]),
                            _c("b-td", { staticClass: "align-middle" }, [
                              _vm._v(_vm._s(a.mask))
                            ]),
                            _c(
                              "b-td",
                              { staticClass: "align-middle" },
                              [
                                _c("b-form-select", {
                                  class:
                                    !+a.qbaccount_id && !+a.ignore
                                      ? "is-invalid"
                                      : +a.qbaccount_id
                                      ? "is-valid"
                                      : "",
                                  attrs: {
                                    options: _vm.accounts,
                                    disabled: +a.ignore > 0
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "first",
                                        fn: function() {
                                          return [
                                            _c(
                                              "b-form-select-option",
                                              { attrs: { value: null } },
                                              [_vm._v("Select Account")]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  ),
                                  model: {
                                    value: a.qbaccount_id,
                                    callback: function($$v) {
                                      _vm.$set(a, "qbaccount_id", $$v)
                                    },
                                    expression: "a.qbaccount_id"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-td",
                              { staticClass: "align-middle text-center" },
                              [
                                _c("b-checkbox", {
                                  class:
                                    !+a.qbaccount_id && !+a.ignore
                                      ? "is-invalid"
                                      : "is-valid",
                                  attrs: {
                                    value: "1",
                                    "unchecked-value": "0",
                                    disabled: +a.qbaccount_id > 0
                                  },
                                  model: {
                                    value: a.ignore,
                                    callback: function($$v) {
                                      _vm.$set(a, "ignore", $$v)
                                    },
                                    expression: "a.ignore"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-td",
                              { staticClass: "align-middle text-center" },
                              [
                                _c("b-checkbox", {
                                  attrs: { value: "1", "unchecked-value": "0" },
                                  model: {
                                    value: a.autosettle,
                                    callback: function($$v) {
                                      _vm.$set(a, "autosettle", $$v)
                                    },
                                    expression: "a.autosettle"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-td",
                              { staticClass: "align-middle text-center" },
                              [
                                _c("PlaidLink", {
                                  attrs: {
                                    "company-id": +_vm.companyId,
                                    "item-id": +a.plaiditem_id,
                                    variant: a.needsauth
                                      ? "danger"
                                      : "secondary",
                                    "button-title": "Re-Auth",
                                    title: "Re-authorize Plaid Bank Account"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      }),
      _c(
        "b-row",
        { staticClass: "text-center m-3", attrs: { "align-h": "around" } },
        [
          _c(
            "b-col",
            { staticClass: "text-left" },
            [
              _c("PlaidLink", {
                attrs: {
                  "company-id": +_vm.companyId,
                  variant: "success",
                  "button-title": "Connect New Plaid Account",
                  title: "Add New Plaid Bank Account"
                }
              })
            ],
            1
          ),
          _vm.accounts.length > 0
            ? _c(
                "b-col",
                [
                  _c("PlaidImport", { attrs: { "company-id": +_vm.companyId } })
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  attrs: {
                    to: "/bookkeeping/code/" + +_vm.companyId,
                    variant: "info"
                  }
                },
                [_vm._v("Transaction Coding Queue")]
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "text-right" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "primary", disabled: _vm.loading },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    }
                  }
                },
                [_vm._v("Save Plaid Account Settings")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }